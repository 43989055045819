
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            import _asaprint_asap_fragments from '@asaprint/asap/fragments.graphql';
            const doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Profile_Load"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RequestUser"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"togglApiKey"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"togglWorkspaceId"},"arguments":[],"directives":[]}]}}]}},{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Profile_Save"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"me"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MeInput"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"profileSave"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"me"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RequestUser"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"togglApiKey"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"togglWorkspaceId"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":284}};
            doc.loc.source = {"body":"\n          #import '@asaprint/asap/fragments.graphql'\n\nquery Profile_Load {\n  me {\n    ...RequestUser\n    togglApiKey\n    togglWorkspaceId\n  }\n}\n\nmutation Profile_Save($me: MeInput!) {\n  profileSave(input: $me) {\n    ...RequestUser\n    togglApiKey\n    togglWorkspaceId\n  }\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(_asaprint_asap_fragments.definitions));


              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const Profile_Load = oneQuery(doc, "Profile_Load");
                
                  export const Profile_Save = oneQuery(doc, "Profile_Save");
                
