import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import ProfilePasswordForm, {
  FormValues as PasswordFormValues,
  validate as passwordValidate,
} from '@asaprint/asap/components/forms/ProfilePasswordForm.js';
import ProfileSettingsForm, {
  FormValues as SettingsFormValues,
  validate as settingsValidate,
} from '@asaprint/asap/components/forms/ProfileSettingsForm.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import { Profile_Load, Profile_Save } from '@asaprint/asap/routes/__authenticated/profile.graphql';
import AuthContext from '@asaprint/asap/contexts/AuthContext.js';
import authenticated from '@asaprint/asap/decorators/authenticated.js';
import {
  Profile_LoadQuery,
  Profile_LoadQueryVariables,
  Profile_SaveMutation,
  Profile_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';

const logger = getLogger('@asaprint/asap/routes/profile');

interface OwnProps {}

type Props = OwnProps;

const Page: React.FunctionComponent<Props> = () => {
  const { data, loading, error } = useQuery<Profile_LoadQuery, Profile_LoadQueryVariables>(Profile_Load);
  const [saveExecute] = useMutation<Profile_SaveMutation, Profile_SaveMutationVariables>(Profile_Save);
  const { login } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const onPasswordChangeSubmit: OnSubmit<PasswordFormValues> = async ({ passwordConfirmation, ...values }) => {
    try {
      const response = await saveExecute({ variables: { me: values } });
      // Refresh user in Auth Context
      login(response.data.profileSave);
      enqueueSnackbar('Zmeny boli úspešne uložené', { variant: 'success' });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const requestUser = data?.me;

  const onSettingsSubmit: OnSubmit<SettingsFormValues> = async ({ togglWorkspaceId, ...values }) => {
    try {
      const response = await saveExecute({
        variables: {
          me: {
            ...values,
            togglWorkspaceId: togglWorkspaceId ? parseFloat(togglWorkspaceId) : null,
          },
        },
      });
      // Refresh user in Auth Context
      login(response.data.profileSave);
      enqueueSnackbar('Zmeny boli úspešne uložené', { variant: 'success' });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <>
      <PageHeader title="Profil - nastavenia" />

      {error || (loading && !data) ? (
        <Loading error={error} />
      ) : (
        <>
          <PagePaper>
            <Form
              defaultValues={{
                id: requestUser.id,
                updatedAt: requestUser.updatedAt,
                password: '',
                passwordConfirmation: '',
                currentPassword: '',
              }}
              onSubmit={onPasswordChangeSubmit}
              validate={passwordValidate}
            >
              <Box sx={{ display: 'none' }}>
                <input type="text" name="username" defaultValue={requestUser.username} autoComplete="username" />
              </Box>
              <ProfilePasswordForm />
              <ConnectedSubmitButtons submitLabel="Zmeniť heslo" noBack />
            </Form>
          </PagePaper>

          <PagePaper sx={{ mt: 2 }}>
            <Form
              defaultValues={{
                id: requestUser.id,
                updatedAt: requestUser.updatedAt,
                togglApiKey: requestUser.togglApiKey ?? '',
                togglWorkspaceId: requestUser.togglWorkspaceId?.toString() ?? '',
              }}
              onSubmit={onSettingsSubmit}
              validate={settingsValidate}
            >
              <ProfileSettingsForm />
              <ConnectedSubmitButtons submitLabel="Uložiť nastavenia" noBack />
            </Form>
          </PagePaper>
        </>
      )}
    </>
  );
};

export default authenticated()(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({
      query: Profile_Load,
    });

    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Profil',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Profil | ${meta.title}`,
  }),
};
