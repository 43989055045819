import TextField from '@engined/client/components/forms/fields/TextField.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { Grid } from '@mui/material';
import React from 'react';

export interface FormValues {
  id: string;
  updatedAt: string;
  togglApiKey: string;
  togglWorkspaceId: string;
}

export function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};
  if (values.togglApiKey || values.togglWorkspaceId) {
    if (!values.togglApiKey) {
      errors.togglApiKey = 'Toggl API kľúč je povinný';
    }

    if (!values.togglWorkspaceId) {
      errors.togglWorkspaceId = 'Workspace ID je povinné';
    } else if (!/\d+/.test(values.togglWorkspaceId)) {
      errors.togglWorkspaceId = 'Workspace ID musí byť číslo';
    }
  }

  return errors;
}

interface Props {}

const ProfileSettingsForm: React.FunctionComponent<Props> = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField fullWidth name="togglApiKey" label="Toggl API kľúč" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField fullWidth name="togglWorkspaceId" label="Toggl Workspace ID" />
      </Grid>
    </Grid>
  );
};

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default React.memo<Props>(ProfileSettingsForm);
