import TextField from '@engined/client/components/forms/fields/TextField.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import isStrongPassword from '@engined/core/validators/isStrongPassword.js';
import { Grid } from '@mui/material';
import React from 'react';

export interface FormValues {
  id: string;
  updatedAt: string;
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
}

export function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};
  if (!values.password) {
    errors.password = 'Nové heslo je povinné';
  }

  if (!values.currentPassword) {
    errors.currentPassword = 'Aktuálne heslo pole je povinné';
  }

  if (values.password && !isStrongPassword(values.password)) {
    errors.password =
      'Heslo nie je dostatočne silné. Musí mať aspoň 8 znakov, 1 malé písmeno, 1 veľké písmeno, 1 číslo a 1 symbol.';
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'Heslá sa nezhodujú';
  }

  return errors;
}

interface Props {}

const ProfilePasswordForm: React.FunctionComponent<Props> = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="currentPassword"
          label="Aktuálne heslo"
          type="password"
          autoComplete="current-password"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField fullWidth name="password" label="Nové heslo" type="password" autoComplete="new-password" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="passwordConfirmation"
          label="Kontrola hesla"
          type="password"
          autoComplete="new-password"
        />
      </Grid>
    </Grid>
  );
};

ProfilePasswordForm.displayName = 'ProfilePasswordForm';

export default React.memo<Props>(ProfilePasswordForm);
